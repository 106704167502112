import React, { ChangeEvent, FormEvent } from 'react';
import Helmet from 'react-helmet';
import _debug from 'debug';
import { graphql } from 'gatsby';
import { injectGlobal } from 'emotion';
import {
  Background,
  Content,
  Section,
  H1,
  H2,
  H3,
  P,
  A,
  Link,
  Iframe,
  Img,
  Photo,
  Label,
  Input,
  Textarea,
  Form,
  Fieldset,
  FormField,
  FormActions,
  Button,
  Divider,
  Amatic,
  ScrollTop,
} from '../components';

const debug = _debug('drewmieke:rsvp');

injectGlobal({
  'html, body': {
    margin: 0,
    fontSize: '16px',
  },
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    transition: 'all 0.2s ease-out',
  },
});

interface RSVPFormProps {
  onYeah: (state: RSVPFormState) => void;
  onNope: (state: RSVPFormState) => void;
}

interface RSVPFormState {
  names: string;
  email: string;
  phone: string;
  address: string;
  attending: string | undefined;
}

type RSVPField = keyof RSVPFormState;

class RSVPForm extends React.Component<RSVPFormProps, RSVPFormState> {
  YEAH = 'yeah';
  NOPE = 'nope';

  STORE = 'drewmieke:rsvp-form';

  state = {
    names: '',
    email: '',
    phone: '',
    address: '',
    message: '',
    attending: undefined,
  };

  constructor(props: RSVPFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    try {
      const storeData = localStorage.getItem(this.STORE);
      debug('RSVPForm storeData', storeData);

      const storeState = storeData ? JSON.parse(storeData) : {};
      debug('RSVPForm storeState', storeState);

      this.state = { ...this.state, ...storeState };
      debug('RSVPForm state', this.state);
    } catch (error) {
      debug('RSVPForm localStorage.getItem', error);
    }
  }

  handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const field: RSVPField = event.target.name as RSVPField;
    const value = event.target.value;
    const fieldUpdate: Partial<RSVPFormState> = { [field]: value };
    const nextState = { ...this.state, ...fieldUpdate };

    debug('RSVP handleChange fieldUpdate', fieldUpdate);
    debug('RSVP handleChange nextState', nextState);

    this.setState(nextState);
  }

  handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const encode = (data: { [key: string]: any }) => {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');
    };

    try {
      localStorage.setItem(this.STORE, JSON.stringify(this.state));
    } catch (error) {
      debug('RSVPForm localStorage setItem', error);
    }

    const body = encode({
      'form-name': 'rsvp',
      ...this.state,
    });

    debug('RSVP handleSubmit body', body);

    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
    })
      .then((result) => {
        debug('RSVP handleSubmit result', result);

        // check Response.ok

        // show thanks
        const { attending } = this.state;
        attending === this.YEAH
          ? this.props.onYeah(this.state)
          : this.props.onNope(this.state);
      })
      .catch((reason) => {
        // show reason
        console.error('RSVP handleSubmit reason', reason);
      });
  }

  render() {
    const canSubmit = this.state.names && this.state.attending !== undefined;

    return (
      <form
        name="rsvp"
        method="post"
        action="/"
        data-netlify
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="form-name" value="rsvp" />
        <input type="hidden" name="bot-field" />

        <Fieldset>
          <FormField>
            <Label>Name(s)</Label>
            <Input
              type="text"
              name="names"
              defaultValue={this.state.names}
              onChange={this.handleChange}
            />
          </FormField>
          <FormField>
            <Label>Email</Label>
            <Input
              type="email"
              name="email"
              defaultValue={this.state.email}
              onChange={this.handleChange}
            />
          </FormField>
          <FormField>
            <Label>Phone</Label>
            <Input
              type="tel"
              name="phone"
              defaultValue={this.state.phone}
              onChange={this.handleChange}
            />
          </FormField>
          <FormField>
            <Label>Address</Label>
            <Textarea
              name="address"
              defaultValue={this.state.address}
              onChange={this.handleChange}
            />
          </FormField>
          <FormField>
            <Label>Message / Dietary Requirements</Label>
            <Textarea
              name="message"
              defaultValue={this.state.message}
              onChange={this.handleChange}
            />
          </FormField>
          <FormField>
            <Label>
              <Input
                type="radio"
                name="attending"
                value={this.YEAH}
                defaultChecked={this.state.attending === this.YEAH}
                onChange={this.handleChange}
              />
              🎉 We'll be there!
            </Label>
            <Label>
              <Input
                type="radio"
                name="attending"
                value={this.NOPE}
                defaultChecked={this.state.attending === this.NOPE}
                onChange={this.handleChange}
              />
              😢 Sorry, we can't make it.
            </Label>
          </FormField>

          <FormActions>
            <Button type="submit" disabled={!canSubmit}>
              Reply
            </Button>
          </FormActions>
        </Fieldset>
      </form>
    );
  }
}

interface RSVPProps {
  photos: {
    form: any;
    yeah: any;
    nope: any;
  };
}

interface RSVPState {
  formState: 'form' | 'yeah' | 'nope';
}

class RSVP extends React.Component<RSVPProps, RSVPState> {
  state = { formState: 'form' } as RSVPState;

  STORE = 'drewmieke:rsvp';

  constructor(props: any) {
    super(props);

    this.handleYeah = this.handleYeah.bind(this);
    this.handleNope = this.handleNope.bind(this);
    this.handleOops = this.handleOops.bind(this);

    try {
      const storeData = localStorage.getItem(this.STORE);
      const storeState = storeData ? JSON.parse(storeData) : {};
      this.state = {
        ...this.state,
        ...storeState,
      };
    } catch (error) {}
  }

  handleYeah() {
    this.setState({ formState: 'yeah' });

    localStorage.setItem(this.STORE, JSON.stringify(this.state));
  }

  handleNope() {
    this.setState({ formState: 'nope' });

    localStorage.setItem(this.STORE, JSON.stringify(this.state));
  }

  handleOops() {
    this.setState({ formState: 'form' });

    localStorage.setItem(this.STORE, JSON.stringify(this.state));
  }

  render() {
    const { formState } = this.state;

    return (
      <React.Fragment>
        <Section>
          <H3>RSVP</H3>

          {formState === 'form' && (
            <>
              <Img fluid={this.props.photos.form} />
              <RSVPForm onYeah={this.handleYeah} onNope={this.handleNope} />
            </>
          )}

          {formState === 'yeah' && (
            <ScrollTop>
              <Img fluid={this.props.photos.yeah} />
              <ThanksYeah />
              <Oops onClick={this.handleOops} />
            </ScrollTop>
          )}

          {formState === 'nope' && (
            <ScrollTop>
              <Img fluid={this.props.photos.nope} />
              <ThanksNope />
              <Oops onClick={this.handleOops} />
            </ScrollTop>
          )}
        </Section>
      </React.Fragment>
    );
  }
}

const ThanksYeah = () => (
  <Section>
    <div
      style={{
        background: 'white',
        padding: '2rem',
        boxShadow: '0 0 2rem 0 rgba(0, 0, 0, 0.2)',
        transform: 'rotate(-3deg)',
        marginTop: '-2rem',
      }}
    >
      <H3>Hooray! 🎉</H3>
      <P>We're super excited to share our day with you. </P>
      <P>See you in Sydney!</P>
      <P variant="small">– Drew & Mieke.</P>
    </div>
  </Section>
);

const ThanksNope = () => (
  <Section>
    <div
      style={{
        background: 'white',
        padding: '2rem',
        boxShadow: '0 0 2rem 0 rgba(0, 0, 0, 0.2)',
        transform: 'rotate(-1deg)',
        marginTop: '-2rem',
      }}
    >
      <H3>Thanks for letting us know</H3>
      <P>We'll miss you.</P>
      <P variant="small">– Drew & Mieke.</P>
    </div>
  </Section>
);

const Oops = ({ onClick }: { onClick: () => void }) => (
  <div style={{ padding: '2rem', textAlign: 'center' }}>
    <Button style={{ border: 'none' }} onClick={onClick}>
      Oops, I need to change my response
    </Button>
  </div>
);

export const pageQuery = graphql`
  query {
    form: file(relativePath: { eq: "IMG_4645.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yeah: file(relativePath: { eq: "IMG_7339.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nope: file(relativePath: { eq: "IMG_3209.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  debug('RSVP data', data);

  return (
    <React.Fragment>
      <Helmet>
        <title>RSVP: Drew & Mieke / Mieke & Drew</title>
        <link
          href="https://fonts.googleapis.com/css?family=Amatic+SC|Give+You+Glory|Lateef|Nothing+You+Could+Do"
          rel="stylesheet"
        />
      </Helmet>
      <Background>
        <Content>
          <Amatic>
            <center>
              <H1>Drew & Mieke</H1>
              <Link to="/">Back to Event</Link>
            </center>
            <RSVP
              photos={{
                form:
                  data.form &&
                  data.form.childImageSharp &&
                  data.form.childImageSharp.fluid,
                yeah:
                  data.yeah &&
                  data.yeah.childImageSharp &&
                  data.yeah.childImageSharp.fluid,
                nope:
                  data.nope &&
                  data.nope.childImageSharp &&
                  data.nope.childImageSharp.fluid,
              }}
            />
            <center>
              <Link to="/">Back to Event</Link>
            </center>
          </Amatic>
        </Content>
      </Background>
    </React.Fragment>
  );
};

export default Page;
